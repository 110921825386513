<template>
  <v-simple-table class="default-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center">Data</th>
          <th class="text-center">Wysokość mostka</th>
          <th class="text-center">2cm nad pępkiem</th>
          <th class="text-center">Wysokość pępka</th>
          <th class="text-center">2cm pod pępkiem</th>
          <th class="text-center">Kresa biała</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="dtrow" rowspan="3" @click.prevent="$refs.date.show = true">
            <LabelDateField
              ref="date"
              :value.sync="value.date"
              :disabled="disabled"
              placeholder="Wybierz..."
              flat
              hideDetails
              noMargins
              solo
            ></LabelDateField>
          </td>
          <td>
            <v-text-field
              v-model="value.sternum_height"
              :disabled="disabled"
              solo
              flat
              placeholder="Wpisz..."
              v-mask="decimalMask"
              hide-details
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="value.above_belly_button"
              :disabled="disabled"
              solo
              flat
              placeholder="Wpisz..."
              v-mask="decimalMask"
              hide-details
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="value.belly_button_height"
              :disabled="disabled"
              solo
              flat
              placeholder="Wpisz..."
              v-mask="decimalMask"
              hide-details
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="value.below_belly_button"
              :disabled="disabled"
              solo
              flat
              placeholder="Wpisz..."
              v-mask="decimalMask"
              hide-details
            ></v-text-field>
          </td>
          <TextAreaCell
            :value.sync="value.linea_alba"
            :disabled="disabled"
          ></TextAreaCell>
        </tr>
        <tr>
          <th class="text-center">Curl up</th>
          <th class="text-center">Test ASLR</th>
          <th class="text-center">Test ABLR</th>
          <th class="text-center" colspan="2">Inne</th>
        </tr>
        <tr>
          <TextAreaCell
            :value.sync="value.curl_up"
            :disabled="disabled"
          ></TextAreaCell>
          <TextAreaCell
            :value.sync="value.test_aslr"
            :disabled="disabled"
          ></TextAreaCell>
          <TextAreaCell
            :value.sync="value.test_ablr"
            :disabled="disabled"
          ></TextAreaCell>
          <TextAreaCell
            colspan="2"
            :value.sync="value.other"
            :disabled="disabled"
          ></TextAreaCell>
        </tr>
        <tr>
          <th class="text-center">Uwagi</th>
          <TextAreaCell
            colspan="5"
            :value.sync="value.comment"
            placeholder="Wpisz uwagi..."
            :disabled="disabled"
          ></TextAreaCell>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const decimalMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  decimalLimit: 5,
  integerLimit: 6,
  includeThousandsSeparator: false,
  allowNegative: false,
});

export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      decimalMask,
    };
  },
  components: {
    LabelDateField: () => import("@/components/LabelDateField"),
    MaxlengthErrorMessage: () =>
      import("@/components/controls/MaxlengthErrorMessage"),
    TextAreaCell: () => import("@/components/tables/TextAreaCell"),
  },
};
</script>

<style lang="scss" scoped>
.default-table {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
}
.default-table ::v-deep td {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  min-width: 90px;
}
.default-table ::v-deep input {
  text-align: center;
}
.default-table ::v-deep th {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  color: black !important;
  font-size: 14px !important;
}
.default-table ::v-deep tr:hover {
  background-color: transparent !important;
}
.dtrow {
  min-width: 125px !important;
}
</style>